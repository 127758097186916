<template>
  <div class="policy-detail-container">
    <div class="banner-limit flex-column flex-start policy-detail-content">
      <div class="flex-column flex-start border-botween detail-title">
        <h1>{{ item.name || "" }}</h1>
        <p>{{ item.createTime || "" }}</p>
      </div>
      <div class="detail-content">
        <div v-html="item.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item: {name:"暂无数据",
			createTime:"暂无数据",
			content:"暂无数据",
			},
    };
  },
  created() {
    this.item = this.$route.query; // 接收的参数ID
		console.log("传入对象"+this.$route.query)
  },
};
</script>

<style lang="scss" scoped>
.policy-detail-content {
  margin-top: 70px;
}
.detail-title {
  color: #333;
  width: 100%;
  border-bottom: 1px solid rgba(49, 47, 47, 0.1);
  p {
    padding: 10px 0;
  }
}
.detail-content {
  width: 60%;
  line-height: 28px;
  font-size: 14px;
  margin: 40px 0;
  overflow: hidden;
  p {
    text-indent: 2rem;
  }
}
</style>